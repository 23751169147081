import { defineStore } from 'pinia'
import { useFetcher } from '@/compose/axios'
import { ref } from 'vue'

const STORE_NAME = 'profile_completion'
//const LOCALSTORAGE_KEY = 'profile_completion_locator'

export const useProfileCompletionStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

  const fromServiceData = ref(false)

  const profile = ref({
    mailing_country: '',
    title: '',
    first_name: '',
    last_name: '',
    code: null,
    phone: null,
    other_phone: null,
    more_information: null,
    home_phone: null,
    office_phone: null,
    phone_send: null,
    other_phone_send: null,
    home_phone_send: null,
    office_phone_send: null,
    isWhatsApp: false,
    isTelegram: false,
    mailing_city: null,
    mailing_street: null,
    website: null,
    invoice_address: null,
    invoice_city: null,
    invoice_state: null,
    invoice_zip: null,
    invoice_country: null,
    invoice_code: null,
    invoice_vat: null,
    contact_before_service: null,
    // number_of_passengers CAN'T BE NULL
    number_of_passengers: 1,
    main_passenger: null,
    relation_passenger: null,
    other_language: 'English',
    change_vehicle: null,
    pickup_specific: null,
    dropoff_specific: null,
    company_name: null,
    address_notes: null,
    number_suitcases: null,
    description: null
  })
  const profileCompletion = async (deal_id) => {
    if (deal_id) {
      let data = {
        ...profile.value,
        phone: profile.value.phone_send,
        home_phone: profile.value.home_phone_send,
        other_phone: profile.value.other_phone_send,
        office_phone: profile.value.office_phone_send
      }
      try {
        await axiosInstance.post(`profileCompletion/${deal_id}`, data)
      } catch (error) {
        console.error(error)
      }
    } else {
      console.error('Deal ID is not set')
    }
  }

  function $reset() {
    fromServiceData.value = false
  }
  return { profile, profileCompletion, fromServiceData, $reset }
})
