<template>
  <GMapMap
    @click="handleMapClick"
    ref="mapRef"
    :center="pathStartFinish.pickup"
    :zoom="zoomLevel"
    map-type-id="styledMap"
    class="mt-3 h-80 w-full"
    :options="{
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true
    }"
  >
    <GMapPolyline v-if="path" :path="path" :options="{ strokeColor: '#5FD052' }" />
    <GMapMarker
      @click="handleMarkerClick('pickup')"
      @dragstart="handleMarkerClick('pickup')"
      :position="pathStartFinish.pickup"
      :icon="marker"
      :draggable="true"
      @dragend="updateMarkerLocation('pickup', $event.latLng)"
    />
    <GMapMarker
      @click="handleMarkerClick('dropoff')"
      @dragstart="handleMarkerClick('dropoff')"
      :position="pathStartFinish.dropoff"
      :icon="marker"
      :draggable="true"
      @dragend="updateMarkerLocation('dropoff', $event.latLng)"
    />
  </GMapMap>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import marker from '~project_assets/images/marker.svg'

import { useGoogleAddress } from '@/compose/googleAddress'
import { useGoogleAddressStore } from '@/stores/googleAddress'
import { storeToRefs } from 'pinia'

const googleAddress = useGoogleAddressStore()

const { activeAuto, pathStartFinish } = storeToRefs(googleAddress)
const axios = inject('axios')
const mapRef = ref(null)
const requestTime = ref(false)
const path = ref(null)
let mapInstance = ref()
let zoomLevel = ref(8)

const { getAddressData } = useGoogleAddress('oneWayTransfer')

const emit = defineEmits(['markerdragged'])

const props = defineProps({
  pickupRef: Object,
  dropoffRef: Object,
  form: Object
})

function handleMapClick(e) {
  googleAddress.updatePickupDropOff(e.latLng, activeAuto.value)
}

function handleMarkerClick(marker) {
  activeAuto.value = marker
}

function updateMarkerLocation(locationType, newLocation) {
  let latLng = { lat: newLocation.lat(), lng: newLocation.lng() }
  googleAddress.updatePickupDropOff(latLng, locationType)
  emit('markerdragged', locationType)

  // eslint-disable-next-line
  const geocoder = new google.maps.Geocoder()

  geocoder.geocode({ location: latLng }, (results, status) => {
    // eslint-disable-next-line
    if (status === google.maps.GeocoderStatus.OK) {
      if (results[0]) {
        console.log(results[0])
        getAddressData(results[0], results[0])
        if (locationType === 'pickup' && props.pickupRef) {
          props.pickupRef.$emit('input', results[0].formatted_address)
          props.form.validate()
        } else if (locationType === 'dropoff' && props.dropoffRef) {
          props.dropoffRef.$emit('input', results[0].formatted_address)
          props.form.validate()
        }
        console.log(props)
      }
    }
  })
}

function fitMapBounds() {
  if (
    mapRef.value &&
    pathStartFinish.value.pickup &&
    pathStartFinish.value.dropoff &&
    mapRef.value.$mapObject
  ) {
    // eslint-disable-next-line
    const bounds = new google.maps.LatLngBounds()
    bounds.extend(pathStartFinish.value.pickup)
    bounds.extend(pathStartFinish.value.dropoff)

    const diffLong = Math.abs(pathStartFinish.value.pickup.lng - pathStartFinish.value.dropoff.lng)
    if (diffLong < 0.01) {
      bounds.extend({
        lat: pathStartFinish.value.pickup.lat,
        lng: pathStartFinish.value.pickup.lng + 1
      })
      bounds.extend({
        lat: pathStartFinish.value.pickup.lat,
        lng: pathStartFinish.value.pickup.lng - 1
      })
    }
    // There is a location where we need to zoom out. Here, we check if the location matches the data in
    // props.form.values. If it matches, we zoom out by 0.7.
    mapRef.value.$mapObject.fitBounds(bounds)
    zoomLevel.value =
      mapRef.value.$mapObject.getZoom() -
      ((props.form.values.pickup === 'Florence, Metropolitan City of Florence, Italy' &&
        props.form.values.dropoff === 'Lucca, Province of Lucca, Italy') ||
      (props.form.values.pickup === 'Florence, Metropolitan City of Florence, Italy' &&
        props.form.values.dropoff === 'Pisa, Province of Pisa, Italy')
        ? 0.7
        : 0)
  }
}

watch(
  pathStartFinish,
  (value) => {
    if (
      Object.prototype.hasOwnProperty.call(value, 'pickup') &&
      Object.prototype.hasOwnProperty.call(value, 'dropoff')
    ) {
      requestTime.value = true

      axios
        .post('/route/path', {
          origin: `${value.pickup.lat},${value.pickup.lng}`,
          destination: `${value.dropoff.lat},${value.dropoff.lng}`,
          key: import.meta.env.VITE_APP_GOOGLE_API_KEY
        })
        .then((value) => {
          requestTime.value = false
          // eslint-disable-next-line
          path.value = new google.maps.geometry.encoding.decodePath(
            value.data.data.routes[0].overview_polyline.points
          )
        })
        .catch((reason) => {
          console.log('catch:', reason)
        })
    }
  },
  { deep: true }
)

onMounted(() => {
  mapRef.value.$mapPromise.then((map) => {
    mapInstance.value = map
    // eslint-disable-next-line
    const styledMapType = new google.maps.StyledMapType([
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#444444'
          }
        ]
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f2f2f2'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100
          },
          {
            lightness: 45
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#46bcec'
          },
          {
            visibility: 'on'
          }
        ]
      }
    ])

    map.mapTypes.set('styledMap', styledMapType)
  })

  fitMapBounds()
  window.vueGoogleMapsInit()

  requestTime.value = true
  axios
    .post('/route/path', {
      origin: `${pathStartFinish.value.pickup.lat},${pathStartFinish.value.pickup.lng}`,
      destination: `${pathStartFinish.value.dropoff.lat},${pathStartFinish.value.dropoff.lng}`,
      key: import.meta.env.VITE_APP_GOOGLE_API_KEY
    })
    .then((value) => {
      requestTime.value = false
      // eslint-disable-next-line
      path.value = new google.maps.geometry.encoding.decodePath(
        value.data.data.routes[0].overview_polyline.points
      )
      setTimeout(() => {
        fitMapBounds()
      }, 200)
    })
    .catch((reason) => {
      console.log(reason)
    })
})
</script>
