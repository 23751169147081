<template>
  <div
    class="font-['Basier Circle'] relative w-full flex-col items-start justify-start gap-10 pt-10 font-normal text-white md:inline-flex"
  >
    <div
      v-if="isShowModal"
      @click="closeModal"
      class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-[#333639] bg-opacity-70 p-4"
    >
      <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
        <div class="flex justify-end p-4">
          <button
            @click="closeModal"
            aria-label="close"
            class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
            type="button"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div class="p-6 pt-0">
          <p>Main Passenger is required field</p>
        </div>
        <div class="border-t border-gray-600 p-6">
          <button
            @click="closeModal"
            type="button"
            class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    <div class="flex">
      <div
        class="data_header text-background mb-3 px-4 text-[40px] font-bold leading-none dark:text-white sm:px-0 md:text-[80px]"
      >
        Service data
      </div>
      <span class="absolute right-0 mt-6 flex gap-1 text-black dark:text-white"
        >Step
        <p class="text-main">5</p>
        of 5</span
      >
    </div>
    <div class="data_description px-4 sm:px-0">
      You are over 90% through the process. The below data is specifically related to the specific
      service.
    </div>
    <div class="mb-6 inline-flex w-full items-center justify-between px-4 sm:px-0 md:w-[486px]">
      <div class="relative h-2 w-4/5 md:w-[415px]">
        <div
          class="absolute left-0 top-0 h-2 w-full rounded-[99px] bg-[#C2D8BF] dark:bg-[#3D4043]"
        ></div>
        <div
          class="dgt-theme rlt-theme bg-main absolute left-0 top-0 h-2 w-[90%] rounded-[99px]"
        ></div>
      </div>
      <div
        class="dgt-theme-progress rlt-theme-progress text-main w-[19%] text-center font-bold leading-snug md:text-xl"
      >
        90 %
      </div>
    </div>
    <div
      class="flex flex-col items-start justify-start gap-8 self-stretch rounded-[40px] bg-white px-4 pb-3 pt-6 dark:bg-[#333639] sm:p-8"
    >
      <div class="text-background text-base leading-relaxed dark:text-white md:text-2xl">
        Service data
      </div>

      <form action="" class="flex w-full flex-col gap-8">
        <div class="flex gap-8 max-lg:flex-col lg:gap-4">
          <div class="flex w-full flex-col gap-2 md:w-auto">
            <span class="text-background dark:text-white">Number of passengers</span>
            <input
              v-model="profile.number_of_passengers"
              @keypress="
                (e) => {
                  // number_of_passengers CAN'T BE NULL
                  if (e.charCode >= 48 && e.charCode <= 57) {
                    return true
                  } else {
                    e.preventDefault()
                  }
                }
              "
              @input="
                // number_of_passengers CAN'T BE NULL
                (e) => {
                  if (profile.number_of_passengers == '') {
                    profile.number_of_passengers = ''
                  }
                }
              "
              type="text"
              class="text-background dark:bg-background rounded-[53px] border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
            />
            <span class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
              >You may add any needed note</span
            >
          </div>
          <div
            class="flex w-full flex-col gap-2 max-lg:border-t-2 max-lg:border-t-[#DDE5DC] max-lg:pt-8 dark:max-lg:border-t-[#D9D9D9]/5 md:grow"
          >
            <span class="text-background dark:text-white">Main passanger</span>
            <input
              v-model="profile.main_passenger"
              type="text"
              placeholder="for example: They are my spouse, fiance, family, boss, colleague, client, etc"
              class="text-background dark:bg-background rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
            />
            <span class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
              >If you are not the passenger yourself,<br />please indicate the name(s) of the lead
              passenger(s)</span
            >
          </div>
          <div
            class="flex w-full flex-col gap-2 max-lg:border-t-2 max-lg:border-t-[#DDE5DC] max-lg:pt-8 dark:max-lg:border-t-[#D9D9D9]/5 md:grow"
          >
            <span class="text-background dark:text-white">Language</span>
            <select
              v-model="profile.other_language"
              name="select"
              class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:w-auto md:text-lg"
            >
              <option value="English">English</option>
              <option value="Italian">Italian</option>
              <option value="Spanish">Spanish</option>
            </select>
            <span class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
              >In our company all our chauffeurs are experienced and speak English if you prefer a
              different language please indicate it</span
            >
          </div>
        </div>
        <div class="flex flex-col gap-8">
          <div
            class="flex gap-8 max-lg:border-t-2 max-lg:border-t-[#DDE5DC] max-lg:pt-8 dark:max-lg:border-t-[#D9D9D9]/5 max-md:flex-col md:gap-4"
          >
            <div class="flex w-full flex-col gap-2">
              <label class="text-background dark:text-white">Pick Up Location Specifics</label>
              <input
                v-model="profile.pickup_specific"
                type="text"
                placeholder="Pick Up Location Specifics"
                class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
              />
              <span class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
                >If applicable please indicate arrival Flight/Train Number of GPS coordinates if
                needed (such as for Villas in the countriside or difficult to reach
                locatitons)</span
              >
            </div>
            <div
              class="flex w-full flex-col gap-2 max-md:border-t-2 max-md:border-t-[#DDE5DC] max-md:pt-8 dark:max-md:border-t-[#D9D9D9]/5"
            >
              <label class="text-background dark:text-white">Drop Off Location Specifics</label>
              <input
                v-model="profile.dropoff_specific"
                type="text"
                placeholder="Drop Off Location Specifics"
                class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
              />
              <span class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
                >If applicable please indicate departure Flight/Train Number of GPS coordinates if
                needed (such as for Villas in the countriside or difficult to reach
                locatitons)</span
              >
            </div>
          </div>
          <div
            class="flex gap-8 max-lg:border-t-2 max-lg:border-t-[#DDE5DC] max-lg:pt-8 dark:max-lg:border-t-[#D9D9D9]/5 max-md:flex-col md:gap-4"
          >
            <div class="relative flex w-full flex-col gap-2">
              <label
                for="large-luggage"
                class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
                >Large pieces of Luggage</label
              >
              <div class="relative">
                <input
                  v-model.number="luggages.large.current"
                  type="text"
                  id="large-luggage"
                  name="large-luggage"
                  class="text-background dark:bg-background w-full rounded-[53px] border-l-0 border-r-0 border-[#878787] bg-[#E8EDE8]/50 px-14 py-4 text-center text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
                  @input="validateInput($event, 'large')"
                />

                <button
                  @click="decrementValue('large')"
                  type="button"
                  class="bg-main dark:text-main absolute left-1 top-1 flex size-11 items-center justify-center rounded-full border border-transparent pb-1.5 font-sans text-4xl font-extralight text-white duration-300 hover:scale-95 dark:border-[#3D4043] dark:bg-[#1E1E20] dark:text-[#5FD052] md:left-1.5 md:top-1.5 md:size-[51px]"
                >
                  –
                </button>
                <button
                  @click="incrementValue('large')"
                  type="button"
                  class="bg-main dark:text-main absolute right-1 top-1 flex size-11 items-center justify-center rounded-full border border-transparent pb-1.5 font-sans text-4xl font-extralight text-white duration-300 hover:scale-95 dark:border-[#3D4043] dark:bg-[#1E1E20] dark:text-[#5FD052] md:right-1.5 md:top-1.5 md:size-[51px]"
                >
                  +
                </button>
              </div>
            </div>
            <div class="relative flex w-full flex-col gap-2">
              <label
                for="small-luggage"
                class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm"
                >Small pieces of Luggage</label
              >
              <div class="relative">
                <input
                  v-model.number="luggages.small.current"
                  type="text"
                  id="small-luggage"
                  name="small-luggage"
                  class="text-background dark:bg-background w-full rounded-[53px] border-l-0 border-r-0 border-[#878787] bg-[#E8EDE8]/50 px-14 py-4 text-center text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
                  @input="validateInput($event, 'small')"
                />

                <button
                  @click="decrementValue('small')"
                  type="button"
                  class="bg-main dark:text-main absolute left-1 top-1 flex size-11 items-center justify-center rounded-full border border-transparent pb-1.5 font-sans text-4xl font-extralight text-white duration-300 hover:scale-95 dark:border-[#3D4043] dark:bg-[#1E1E20] dark:text-[#5FD052] md:left-1.5 md:top-1.5 md:size-[51px]"
                >
                  –
                </button>
                <button
                  @click="incrementValue('small')"
                  type="button"
                  class="bg-main dark:text-main absolute right-1 top-1 flex size-11 items-center justify-center rounded-full border border-transparent pb-1.5 font-sans text-4xl font-extralight text-white duration-300 hover:scale-95 dark:border-[#3D4043] dark:bg-[#1E1E20] dark:text-[#5FD052] md:right-1.5 md:top-1.5 md:size-[51px]"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div class="flex w-full flex-col gap-2">
            <span class="text-xs text-[#3D4043] dark:text-[#C8C8C8] md:text-sm">Your notes</span>
            <input
              v-model="profile.description"
              type="text"
              placeholder="Notes"
              class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
            />
          </div>
          <div
            class="flex flex-col gap-5 max-lg:border-t-2 max-lg:border-t-[#DDE5DC] max-lg:pt-8 dark:max-lg:border-t-[#D9D9D9]/5"
          >
            <p class="text-background leading-tight dark:text-white md:text-xl">
              If we ran in shortage of availability for the model of vehile you selected,<br />
              would you mind if we dispatched a superior model as a complimentary upgrade?
            </p>
            <div class="flex flex-col gap-4 md:flex-row">
              <div class="flex cursor-pointer items-center">
                <input
                  v-model="profile.change_vehicle"
                  checked
                  id="default-radio-5"
                  type="radio"
                  value="No"
                  name="default-radio"
                  class="radio-dgt radio-rlt text-main border-main h-6 w-6 cursor-pointer cursor-pointer border bg-transparent"
                />
                <label
                  for="default-radio-5"
                  class="text-background ms-2 cursor-pointer text-sm dark:text-white md:text-xl"
                  >No</label
                >
              </div>
              <div class="flex cursor-pointer items-center">
                <input
                  v-model="profile.change_vehicle"
                  id="default-radio-3"
                  type="radio"
                  value="Please advise me, before you dispatch a different vehicle"
                  name="default-radio"
                  class="radio-dgt radio-rlt text-main border-main h-6 w-6 cursor-pointer cursor-pointer border bg-transparent"
                />
                <label
                  for="default-radio-3"
                  class="text-background ms-2 cursor-pointer text-sm dark:text-white md:text-xl"
                  >Please advise me, before you dispatch a different vehicle</label
                >
              </div>
              <div class="flex cursor-pointer items-center">
                <input
                  v-model="profile.change_vehicle"
                  id="default-radio-4"
                  type="radio"
                  value="Yes, I only want the vehicles I selected"
                  name="default-radio"
                  class="radio-dgt radio-rlt text-main border-main h-6 w-6 cursor-pointer cursor-pointer border bg-transparent"
                />
                <label
                  for="default-radio-4"
                  class="text-background ms-2 cursor-pointer text-sm dark:text-white md:text-xl"
                  >Yes, I only want the vehicles I selected</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
      <form
        action=""
        class="w-full max-lg:border-t-2 max-lg:border-t-[#DDE5DC] max-lg:pt-8 dark:max-lg:border-t-[#D9D9D9]/5"
      >
        <div class="flex gap-5 max-md:flex-col md:items-center">
          <p class="text-background leading-tight tracking-[-0.32px] dark:text-white md:text-xl">
            Would you enjoy being notified by email with Driver Contact Data before the service?
          </p>
          <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex cursor-pointer items-center gap-3">
              <input
                v-model="profile.contact_before_service"
                checked
                id="default-radio-9"
                type="radio"
                value="Yes"
                name="default-radio"
                class="radio-dgt radio-rlt text-main border-main h-6 w-6 cursor-pointer border bg-transparent"
              />
              <label
                for="default-radio-9"
                class="text-background cursor-pointer text-sm dark:text-white md:text-xl"
                >Yes</label
              >
            </div>
            <div class="flex cursor-pointer items-center gap-3">
              <input
                v-model="profile.contact_before_service"
                id="default-radio-8"
                type="radio"
                value="No"
                name="default-radio"
                class="radio-dgt radio-rlt text-main border-main h-6 w-6 cursor-pointer cursor-pointer border bg-transparent"
              />
              <label
                for="default-radio-8"
                class="text-background cursor-pointer text-sm dark:text-white md:text-xl"
                >No</label
              >
            </div>
          </div>
        </div>
      </form>
      <div
        class="dgt-theme-btn rlt-theme-btn bg-main inline-flex h-16 w-full cursor-pointer items-center justify-center gap-2.5 rounded-[37px] px-4 py-3"
        @click="submitProfile"
      >
        <div class="text-background cursor-default font-semibold leading-tight md:text-lg">
          Submit
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useProfileCompletionStore } from '@/stores/profile_completion'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

const orderStore = useOrderStore()
const profileCompletionStore = useProfileCompletionStore()
const router = useRouter()
const { profile, fromServiceData } = storeToRefs(profileCompletionStore)
const { orderData } = storeToRefs(orderStore)

const isShowModal = ref(false)

const luggages = ref({
  large: {
    current: 0,
    max: 7
  },
  small: {
    current: 0,
    max: 10
  }
})

const validateInput = (event, type) => {
  const cleanedValue = event.target.value.replace(/\D/g, '')
  if (cleanedValue > luggages.value[type].max) {
    luggages.value[type].current = luggages.value[type].max
  } else {
    luggages.value[type].current = cleanedValue
  }
}

function decrementValue(type) {
  const newValue = parseInt(luggages.value[type].current) - 1
  luggages.value[type].current = newValue >= 0 ? newValue : 0
}

function incrementValue(type) {
  const newValue = parseInt(luggages.value[type].current) + 1
  luggages.value[type].current =
    newValue <= luggages.value[type].max ? newValue : luggages.value[type].max
}
function closeModal() {
  isShowModal.value = false
}

const submitProfile = () => {
  fromServiceData.value = true
  profile.value.number_of_passengers = parseInt(profile.value.number_of_passengers)

  profile.value.number_suitcases = `Large: ${luggages.value.large.current}; Small: ${luggages.value.small.current}`

  orderData.value.allowedPages['success'] = 1
  profileCompletionStore.profileCompletion(orderData.value.deal_id)
  router.push('/success')
}
</script>
