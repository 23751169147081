<template>
  <div>
    <div class="payment_timer_text" v-show="showTimer">
      This offer will expire within the next:
      <div class="countdown_wrapper payment_timer">
        <div class="countdown" id="countdown">
          <div class="countdown-number">
            <span class="minutes">{{ remainingTime.minutes }}</span>
          </div>
          <span class="countdown-text">:</span>
          <div class="countdown-number">
            <span class="seconds">{{ remainingTime.seconds }}</span>
          </div>
        </div>
      </div>
      after which the price might be different
    </div>
    <p id="time_over" v-show="!showTimer" class="payment_timer_text text-error">
      The time to book this ride online has expired, but no worries, our consultants will reach out
      to you with an updated offer in due course.
    </p>
  </div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useOrderStore } from '@/stores/order'
const storage = inject('storage')
const orderStore = useOrderStore()
const { orderData } = storeToRefs(orderStore)
// const countdownTimer = ref(orderData.value.countdown)
const showTimer = ref(true)
// const countdownEndTime = ref(new Date().getTime() + 5 * 60000)
const remainingTime = ref({ minutes: 5, seconds: 0 })
const props = defineProps(['initial_time', 'timerExpires'])
const initial_time = ref(props.initial_time)
const timerExpires = ref(props.timerExpires)
const timerExpiresCss = () => {
  if (document.getElementById('countdown_wrap')) {
    document.getElementById('countdown_wrap').style.display = 'none'
  }
  if (document.getElementById('payment-form')) {
    document.getElementById('payment-form').style.display = 'none'
  }
  if (document.getElementById('time_over')) {
    document.getElementById('time_over').style.display = 'block'
  }
}
onMounted(() => {
  if (timerExpires.value) {
    showTimer.value = false
    timerExpiresCss()
  }
  let now = new Date()
  let countdown = new Date(now.getTime() + 5 * 60000)
  storage.setItem('countdown', countdown, 'session')
  function getTimeRemaining(endtime) {
    // let t = Date.parse(endtime) - Date.parse(new Date().toString()) // Default
    var nowRome = new Date(
      new Date(new Date().getTime() - 3580000).toLocaleString('en-US', { timeZone: 'Europe/Rome' })
    )
    let t = Date.parse(endtime) - Date.parse(nowRome)
    let seconds = Math.floor((t / 1000) % 60)
    let minutes = Math.floor((t / 1000 / 60) % 60)
    return {
      total: t,
      minutes: minutes,
      seconds: seconds
    }
  }
  function initializeClock(id) {
    let clock = document.getElementById(id)
    let minutesSpan = clock.querySelector('.minutes')
    let secondsSpan = clock.querySelector('.seconds')
    function updateClock() {
      let t = getTimeRemaining(initial_time.value)
      minutesSpan.innerHTML = ('0' + t.minutes).slice(-2)
      secondsSpan.innerHTML = ('0' + t.seconds).slice(-2)
      if (t.total <= 0 && t.total != null) {
        clearInterval(timeinterval)
        showTimer.value = false
        orderData.value.timer_expires = 1

        timerExpiresCss()
      }
    }
    updateClock()
    let timeinterval = setInterval(updateClock, 1000)
    if (orderData.value.countdown === 'stop') {
      clearInterval(timeinterval)
    }
  }
  initializeClock('countdown')
})
// watch(countdownEndTime, () => {
//   updateRemainingTime()
// })
</script>
