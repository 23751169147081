<template>
  <div
    class="dark:bg-background border-main mb-6 rounded-3xl border bg-[#E8EDE8]/50 px-5 py-8 text-white dark:border-[#CCF2C8]"
  >
    <h1 class="mb-5 text-black dark:text-white lg:text-2xl lg:font-semibold">
      Previously Entered Contacts
    </h1>
    <div class="flex justify-between gap-6 max-lg:flex-col lg:items-center">
      <div>
        <div class="grid gap-6 *:space-y-1.5 sm:grid-cols-2 md:grid-cols-4 lg:gap-12 xl:text-lg">
          <div>
            <p class="text-[#878787] dark:text-[#C8C8C8]">First Name:</p>
            <p class="text-background break-words dark:text-white">
              {{ lastUsedContact.first_name }}
            </p>
          </div>
          <div>
            <p class="text-[#878787] dark:text-[#C8C8C8]">Last Name:</p>
            <p class="text-background break-words dark:text-white">
              {{ lastUsedContact.last_name }}
            </p>
          </div>
          <div>
            <p class="text-[#878787] dark:text-[#C8C8C8]">E-mail Address:</p>
            <p class="text-background break-words dark:text-white">
              {{ lastUsedContact.email }}
            </p>
          </div>
          <div>
            <p class="text-[#878787] dark:text-[#C8C8C8]">Phone number:</p>
            <p class="text-background flex break-words dark:text-white">
              {{ lastUsedContact.phone }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex space-x-4">
        <!-- <button class="button" @click="contactLastUsage">Select Previously Entered Contacts</button> -->
        <button
          @click="sideBarStore.update(true)"
          class="text-background w-full rounded-full border border-[#878787] px-6 py-4 font-semibold dark:border-[#C8C8C8] dark:text-white lg:w-max lg:py-5 lg:text-lg"
        >
          Select Others
        </button>
      </div>
      <teleport to="body">
        <ChooseAllContact @selectContact="selectContact($event)" />
      </teleport>
    </div>
  </div>
</template>

<script setup>
import ChooseAllContact from './ChooseAllContact.vue'
import { useContactsStore } from '@/stores/contacts.js'
import { useSideBarStore } from '@/stores/sidebar.js'
import { ref, onBeforeMount } from 'vue'

const sideBarStore = useSideBarStore()
const contactsStore = useContactsStore()
const lastUsedContact = ref({})

onBeforeMount(() => {
  lastUsedContact.value = contactsStore.getLastUsage()
  emit('getContact', lastUsedContact.value)
})

function selectContact(data) {
  emit('getContact', data)
}

const emit = defineEmits(['getContact'])

// function contactLastUsage() {
//   orderStore.update(contactsStore.getLastUsage())
// }
// function contactRemove(contact) {
//   contactsStore.remove(contact.first_name)
// }
</script>
