<template>
  <Form
    @submit="onFormSubmit"
    class="form"
    :validation-schema="contactDataSchema"
    v-slot="{ errors, isSubmitting }"
  >
    <span v-if="isSubmitting">
      {{ errorFill(errors) }}
    </span>
    <div
      v-if="!utils.isEmpty(errorOnForm) && isShowModal"
      @click="closeModal"
      class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
    >
      <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
        <div class="flex justify-end p-4">
          <button
            @click="closeModal"
            aria-label="close"
            class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
            type="button"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div class="p-6 pt-0">
          <p v-for="error in errors" :key="error">{{ error }}</p>
        </div>
        <div class="border-t border-gray-600 p-6">
          <button
            @click="closeModal"
            type="button"
            class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
          >
            Ok
          </button>
        </div>
      </div>
    </div>

    <div
      class="font-['Basier Circle'] relative w-full flex-col items-start justify-start gap-10 pt-10 font-normal text-white md:inline-flex"
    >
      <div class="flex justify-between">
        <div
          class="data_header text-background mb-3 px-4 text-[40px] font-bold leading-none dark:text-white sm:px-0 md:text-[80px]"
        >
          {{
            needInvoice === 'Company or Fiscal Entity that requires and invoice'
              ? 'Account Data'
              : 'Contact Data Required'
          }}
        </div>
        <span class="absolute right-0 !mt-9 flex gap-1 text-black dark:text-white"
          >Step
          <p class="text-main">4</p>
          of 5</span
        >
      </div>
      <div class="data_description px-4 sm:px-0">
        <!-- "Dear {{ orderData.first_name }} {{ orderData.last_name }}, thank you very much, we have
        received your payment of Euro {{ orderData.total }}. Please complete the required fields ()
        below. Sharing additional details helps us personalize your experience and offer relevant
        support in the future" -->
        "Complete the required fields (*) below. Sharing additional details helps us personalize
        your experience and offer relevant support in the future"
      </div>
      <div class="mb-6 inline-flex w-full items-center justify-between px-4 sm:px-0 md:w-[486px]">
        <div class="relative h-2 w-4/5 md:w-[415px]">
          <div
            class="absolute left-0 top-0 h-2 w-full rounded-[99px] bg-[#C2D8BF] dark:bg-[#3D4043]"
          ></div>
          <div
            class="dgt-theme rlt-theme bg-main absolute left-0 top-0 h-2 w-3/5 rounded-[99px]"
          ></div>
        </div>
        <div
          class="dgt-theme-progress rlt-theme-progress text-main w-[19%] text-center font-bold leading-snug md:text-xl"
        >
          60 %
        </div>
      </div>
      <div
        class="flex flex-col items-start justify-start gap-4 self-stretch rounded-[40px] bg-white px-4 pb-3 pt-6 dark:bg-[#333639] sm:p-8"
      >
        <div class="text-background text-base leading-relaxed dark:text-white md:text-2xl">
          Account data
        </div>
        <div class="flex w-full flex-col gap-4 md:flex-row">
          <div class="mr-2 w-full md:max-w-36">
            <Field name="title" v-model="profile.title">
              <div class="relative">
                <div
                  @click="toggleDropdown"
                  class="text-background dark:bg-background flex w-full cursor-pointer items-center justify-between rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none dark:border-[#3D4043] dark:text-white md:text-lg lg:max-w-[139px]"
                >
                  <p v-if="!profile.title" class="text-[#878787]">
                    Select <span class="text-[#E30000]">*</span>
                  </p>
                  <p v-else>{{ profile.title }}</p>
                  <img class="dark:invert" src="@/assets/dli/images/select_arrow.png" alt="" />
                </div>
                <div
                  v-if="isOpen"
                  class="dark:bg-background absolute z-10 mt-2 h-max w-full space-y-1 rounded-[20px] border border-[#878787] bg-[#F4F6F3] px-3 py-5 text-sm text-[#878787] dark:border-[#3D4043]"
                >
                  <div
                    v-for="option in options"
                    :key="option"
                    @click="selectOption(option)"
                    class="hover:text-background cursor-pointer rounded-full p-3 hover:bg-[#CCF2C8] dark:hover:bg-[#CCF2C8]/5 dark:hover:text-white"
                    :class="{
                      'text-background pointer-events-none !cursor-default bg-[#CCF2C8] dark:bg-[#CCF2C8]/5 dark:text-white':
                        profile.title === option
                    }"
                  >
                    {{ option }}
                  </div>
                </div>
              </div>
            </Field>
          </div>
          <Field name="first_name" v-slot="{ field }">
            <input
              v-bind="field"
              v-model="profile.first_name"
              placeholder="Passengers Name/Data"
              class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
            />
          </Field>
          <Field name="last_name" v-slot="{ field }">
            <input
              v-bind="field"
              v-model="profile.last_name"
              placeholder="Last name*"
              class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
            />
          </Field>
        </div>
        <div class="w-full">
          <div class="mb-4 flex flex-col gap-4 lg:flex-row">
            <Field name="mailing_country" v-model="selectedCountry" v-slot="{ field }">
              <select
                v-bind="field.value"
                v-model="selectedCountry"
                @change="changeCountry"
                class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg lg:w-1/4"
              >
                <option v-for="val in countries" :key="val">{{ val }}</option>
              </select>
            </Field>
            <input
              v-model="profile.mailing_city"
              @blur="cityChanged"
              type="text"
              placeholder="City"
              class="text-background dark:bg-background w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg lg:w-1/4"
            />
            <div
              class="ym-record-keys text-background dark:bg-background w-full rounded-[53px] border border-[#878787] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white lg:w-2/3"
              maxlength="230"
            >
              <vue-tel-input
                v-model="profile.phone"
                @country-changed="countryChanged"
                @onInput="(formatted, object) => phoneChange(formatted, object, 'main')"
                v-on:beforeinput="utils.isNumber($event)"
                class="vue-tel-input pl-25 text-background dark:!bg-background border !border-[#878787] !bg-[#E8EDE8]/50 text-sm placeholder:text-[#878787] dark:!border-[#3D4043] dark:text-white md:text-lg"
                placeholder="Enter a phone number"
                v-bind="bindProps"
              ></vue-tel-input>
            </div>
            <div class="w-full lg:w-2/3">
              <span class="data_text">Indicate if Chats Services are used on this number</span>
              <div class="mt-3 flex gap-5">
                <label class="inline-flex cursor-pointer items-center">
                  <input
                    v-model="profile.isWhatsApp"
                    @change="checkTurnMessengers"
                    type="checkbox"
                    value=""
                    class="peer sr-only"
                  />
                  <input type="checkbox" value="" class="peer sr-only" />
                  <div
                    class="peer-checked:outline-main peer-checked:after:border-main peer-checked:after:bg-main dark:bg-background peer relative h-6 w-11 rounded-full bg-[#F4F6F3] outline outline-2 outline-[#898989] after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-[#878787] after:bg-[#878787] after:transition-all after:content-[''] peer-checked:bg-[#BEE7B9] peer-checked:after:translate-x-full dark:after:border-[#E5E5EA] dark:after:bg-[#E5E5EA] dark:peer-checked:bg-[#45784E] rtl:peer-checked:after:-translate-x-full"
                  ></div>
                  <span class="data_invoice-span">WhatsApp</span>
                </label>
                <label class="inline-flex cursor-pointer items-center">
                  <input
                    v-model="profile.isTelegram"
                    @change="checkTurnMessengers"
                    type="checkbox"
                    value=""
                    class="peer sr-only"
                  />
                  <div
                    class="peer-checked:outline-main peer-checked:after:border-main peer-checked:after:bg-main dark:bg-background peer relative h-6 w-11 rounded-full bg-[#F4F6F3] outline outline-2 outline-[#898989] after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-[#878787] after:bg-[#878787] after:transition-all after:content-[''] peer-checked:bg-[#BEE7B9] peer-checked:after:translate-x-full dark:after:border-[#E5E5EA] dark:after:bg-[#E5E5EA] dark:peer-checked:bg-[#45784E] rtl:peer-checked:after:-translate-x-full"
                  ></div>
                  <span class="data_invoice-span">Telegram</span>
                </label>
                <div class="flex cursor-pointer items-center">
                  <input
                    id="default-radio-2"
                    type="radio"
                    v-model="noField"
                    v-bind:checked="noField"
                    @change="(e) => checkTurnMessengers(e, true)"
                    value=""
                    name="default-radio"
                    class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border border-[#898989] bg-transparent"
                  />
                  <label for="default-radio-2" class="data_invoice-span cursor-pointer">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:flex md:gap-4">
            <div class="ym-record-keys mb-4 w-full rounded-[53px] lg:w-1/3" maxlength="230">
              <vue-tel-input
                v-model="profile.other_phone"
                @country-changed="countryChanged"
                @onInput="(formatted, object) => phoneChange(formatted, object, 'other')"
                v-on:beforeinput="utils.isNumber($event)"
                class="vue-tel-input pl-25 text-background dark:!bg-background border border-[#878787] !bg-[#E8EDE8]/50 text-sm placeholder:text-[#878787] dark:!border-[#3D4043] dark:text-white md:text-lg"
                v-bind="bindPropsOther"
              ></vue-tel-input>
            </div>
            <div class="ym-record-keys mb-4 w-full rounded-[53px] lg:w-1/3" maxlength="230">
              <vue-tel-input
                v-model="profile.home_phone"
                @country-changed="countryChanged"
                @onInput="(formatted, object) => phoneChange(formatted, object, 'home')"
                v-on:beforeinput="utils.isNumber($event)"
                class="vue-tel-input pl-25 text-background dark:!bg-background border border-[#878787] !bg-[#E8EDE8]/50 text-sm placeholder:text-[#878787] dark:!border-[#3D4043] dark:text-white md:text-lg"
                v-bind="bindPropsHome"
              ></vue-tel-input>
            </div>
            <div class="ym-record-keys mb-4 w-full rounded-[53px] lg:w-1/3" maxlength="230">
              <vue-tel-input
                v-model="profile.office_phone"
                @country-changed="countryChanged"
                @onInput="(formatted, object) => phoneChange(formatted, object, 'office')"
                v-on:beforeinput="utils.isNumber($event)"
                class="vue-tel-input pl-25 text-background dark:!bg-background border !border-[#878787] !bg-[#E8EDE8]/50 text-sm placeholder:text-[#878787] dark:!border-[#3D4043] dark:text-white md:text-lg"
                v-bind="bindPropsOffice"
              ></vue-tel-input>
            </div>
          </div>
          <input
            v-model="profile.more_information"
            type="text"
            placeholder="More information"
            class="text-background dark:bg-background mb-2 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
          />
          <span class="data_text">
            We will try to avoid calling and disturbing you while you travel with us, but if really
            necessary, please tell us what you consider need and wanted in regards to receiving
            phone calls from us.
          </span>
          <Field name="website" v-slot="{ field }">
            <input
              v-model="profile.website"
              v-bind="field"
              type="text"
              placeholder="Client web site here, please enter the completeURL such as https://mysite.com or the like"
              class="text-background dark:bg-background mb-2 mt-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:text-lg"
            />
          </Field>
          <span class="data_text">
            If you wish, please indicate the web site or sites which you feel it would be
            appropriate that we consult to better understand who you are
          </span>
        </div>
        <form action="" class="w-full">
          <div class="my-2 flex flex-col gap-6 md:my-4 md:content-center">
            <span class="data_invoice-header"> What fiscal documents should we issue?</span>
            <div class="flex flex-col gap-4 lg:flex-row">
              <div class="flex cursor-pointer items-center gap-3">
                <input
                  id="yes-invoice"
                  type="radio"
                  value="Simple reecipt to Private Person"
                  name="invoice-radio"
                  class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border border-[#898989] bg-transparent"
                  @change="onInvoiceTypeChange"
                  v-model="needInvoice"
                />
                <label
                  for="yes-invoice"
                  class="text-background cursor-pointer text-sm dark:text-white"
                  >Simple reecipt to Private Person</label
                >
              </div>
              <div class="flex cursor-pointer items-center gap-3">
                <input
                  id="no-invoice"
                  type="radio"
                  value="Company or Fiscal Entity that requires and invoice"
                  name="invoice-radio"
                  class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border border-[#898989] bg-transparent"
                  @change="headerTitle = 'Account Data'"
                  v-model="needInvoice"
                />
                <label
                  for="no-invoice"
                  class="text-background cursor-pointer text-sm dark:text-white"
                  >Company or Fiscal Entity that requires and invoice</label
                >
              </div>
            </div>
          </div>
          <div v-if="needInvoice === 'Simple reecipt to Private Person'" class="mt-8 md:mt-11">
            <span class="text-background dark:text-white">Personal Address</span>
            <div class="mt-6">
              <span class="data_text">
                Adress is optional, we may use it in case we run campains on your areas or to send
                you goodwill promotional items
              </span>
              <div class="mt-4 w-full md:flex md:gap-4 md:text-lg">
                <input
                  v-model="profile.invoice_address"
                  type="text"
                  placeholder="Mailing Address"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg lg:w-2/3"
                />
                <input
                  v-model="profile.invoice_city"
                  @blur="cityChanged"
                  type="text"
                  placeholder="City"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg lg:w-1/3"
                />
                <input
                  v-model="profile.invoice_zip"
                  type="text"
                  placeholder="Postal / Zip Code"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg lg:w-1/3"
                />
              </div>
              <div class="md:flex md:gap-4">
                <input
                  v-model="profile.invoice_state"
                  type="text"
                  placeholder="State/Region/Province"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
                <input
                  v-model="profile.invoice_country"
                  type="text"
                  placeholder="Country"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
                <input
                  v-model="profile.address_notes"
                  type="text"
                  placeholder="Notes on this Address"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
              </div>
              <div class="md:flex md:gap-4"></div>
            </div>
          </div>
          <div
            v-if="needInvoice === 'Company or Fiscal Entity that requires and invoice'"
            class="mt-8 md:mt-11"
          >
            <span class="data_invoice-header">Commercial data</span>
            <div class="mt-4">
              <div class="w-full md:flex md:gap-4">
                <input
                  v-model="profile.company_name"
                  type="text"
                  placeholder="Company Name*"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg lg:w-2/5"
                />
                <input
                  v-model="profile.invoice_address"
                  type="text"
                  placeholder="Street Address*"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg lg:w-2/5"
                />
                <input
                  v-model="profile.invoice_zip"
                  type="text"
                  placeholder="Postal / Zip Code*"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg lg:w-1/5"
                />
              </div>
              <div class="md:flex md:gap-4">
                <input
                  v-model="profile.invoice_city"
                  type="text"
                  placeholder="City (Legal Address)*"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
                <input
                  v-model="profile.invoice_state"
                  type="text"
                  placeholder="State/Region/Province*"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
                <input
                  v-model="profile.invoice_country"
                  type="text"
                  placeholder="Country of Establishment*"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
              </div>
              <div class="md:flex md:gap-4">
                <input
                  v-model="profile.invoice_code"
                  type="text"
                  placeholder="Fiscal code"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
                <input
                  v-model="profile.invoice_vat"
                  type="text"
                  placeholder="VAT"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
                <input
                  v-model="profile.address_notes"
                  type="text"
                  placeholder="Notes on this Address"
                  class="text-background dark:bg-background mb-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] placeholder:text-[#878787] dark:border-[#3D4043] dark:text-white md:grow md:text-lg"
                />
              </div>
            </div>
          </div>
        </form>
        <button
          class="dgt-theme-btn rlt-theme-btn bg-main inline-flex h-16 w-full cursor-pointer items-center justify-center gap-2.5 rounded-[37px] px-4 py-3"
          type="submit"
        >
          <div class="text-background text-base font-semibold leading-tight md:text-lg">
            Go to next step
          </div>
        </button>
      </div>
    </div>
  </Form>
</template>

<script setup>
import { ref, reactive, inject, watch, onBeforeMount, onMounted } from 'vue'
import * as yup from 'yup'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useOrderStore } from '@/stores/order'
import { useProfileCompletionStore } from '@/stores/profile_completion'
import { Field, Form } from 'vee-validate'
// import { FwbDropdown } from 'flowbite-vue'
const utils = inject('utils')
const needInvoice = ref('')
const profileCompletionStore = useProfileCompletionStore()
const orderStore = useOrderStore()
const router = useRouter()
const selectedCountry = ref('')

const { orderData } = storeToRefs(orderStore)
const { profile } = storeToRefs(profileCompletionStore)

const code = ref(null)
const noField = ref(true)
const country_prefix = ref(null)
const isShowModal = ref(true)
const errorOnForm = ref(null)
const errorFill = (errorBag) => {
  errorOnForm.value = errorBag
}

watch(errorOnForm, (newVal) => {
  if (!utils.isEmpty(newVal)) {
    isShowModal.value = true
  }
})

const options = ['Mr.', 'Ms.', 'Mrs.', 'Miss', 'Mx.', 'Dr.', 'Prof.']
const isOpen = ref(false)

const countries = [
  'Åland Islands',
  'Afghanistan',
  'Akrotiri',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Ashmore and Cartier Islands',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Bassas Da India',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burma',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Caribbean Netherlands',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Clipperton Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Cook Islands',
  'Coral Sea Islands',
  'Costa Rica',
  'Cote D’Ivoire',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czech Republic',
  'Democratic Republic of the Congo',
  'Denmark',
  'Dhekelia',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Europa Island',
  'Falkland Islands (Islas Malvinas)',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern and Antarctic Lands',
  'Gabon',
  'Gaza Strip',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Glorioso Islands',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Jan Mayen',
  'Japan',
  'Jersey',
  'Jordan',
  'Juan De Nova Island',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Navassa Island',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paracel Islands',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of the Congo',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint BarthÃ©lemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Korea',
  'South Sudan',
  'Spain',
  'Spratly Islands',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'The Bahamas',
  'The Gambia',
  'Timor-leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tromelin Island',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands',
  'Wake Island',
  'Wallis and Futuna',
  'West Bank',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

const country = ref('')

onMounted(() => {
  let savedCountry = ''
  if (localStorage.getItem('selectedCountry')) {
    savedCountry = localStorage.getItem('selectedCountry')
  } else {
    savedCountry = country.value
  }

  if (savedCountry) {
    selectedCountry.value = savedCountry
    profile.value.mailing_country = savedCountry
  }
})

const changeCountry = () => {
  profile.value.mailing_country = selectedCountry.value
}

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

const selectOption = (option) => {
  profile.value.title = option
  isOpen.value = false
}

function closeModal() {
  isShowModal.value = false
}

const contactDataSchema = yup.object({
  title: yup.string().required('Select an appeal option').max(230),
  first_name: yup.string().required('First Name is a required field').max(230),
  last_name: yup.string().required('Last Name is a required field').max(230),
  mailing_country: yup.string().required('Country of Residence is a required field').max(230),
  website: yup.string().url().nullable()
})

const phoneChange = (formattedNumber, { number, country }, type) => {
  if (type == 'main') {
    profile.value.phone_send = number
    profile.value.code = country.dialCode
  }
  if (type == 'home') profile.value.other_phone_send = number
  if (type == 'office') profile.value.home_phone_send = number
  if (type == 'other') profile.value.office_phone_send = number
}

const countryChanged = (country) => {
  //TODO: Make change a country list with phone callback and make selected mailing_country
  // save localStorage
  const countryName = country.name.split(' (')[0]
  localStorage.setItem('selectedCountry', countryName)
  selectedCountry.value = countryName
  country.value = countryName
  code.value = country.dialCode
  country_prefix.value = country.name
}

const checkTurnMessengers = (e = null, isNoField = false) => {
  if (!isNoField) {
    if (profile.value.isTelegram || profile.value.isWhatsApp) {
      noField.value = false
    } else {
      noField.value = true
    }
  } else {
    if (e.target.checked === true) {
      profile.value.isTelegram = false
      profile.value.isWhatsApp = false
    }
  }
}

const cityChanged = () => {
  console.log('profile.mailing_city:', profile.value.mailing_city)
  console.log('profile.invoice_city:', profile.value.invoice_city)
  console.log('profile profile.mailing_country:', profile.mailing_country)
}

const onInvoiceTypeChange = () => {
  if (needInvoice.value === 'Simple reecipt to Private Person') {
    cityChanged()
  }
}

const preferredCountries = ['us', 'it', 'au', 'uk', 'in', 'ca', 'il', 'sg', 'ae']

const defaultSettingsProps = {
  mode: 'auto',
  autoFormat: false,
  preferredCountries: preferredCountries,
  inputClasses: 'input',
  dropdownOptions: {
    showFlags: true,
    showDialCodeInList: true,
    showDialCodeInSelection: false
  }
}

const bindProps = reactive({
  ...defaultSettingsProps
})

const bindPropsOther = reactive({
  inputOptions: {
    placeholder: 'Alternative mobile phone you will use on this trip'
  },
  ...defaultSettingsProps
})

const bindPropsHome = reactive({
  inputOptions: {
    placeholder: 'Home Phone'
  },
  ...defaultSettingsProps
})
const bindPropsOffice = reactive({
  inputOptions: {
    placeholder: 'Office Phone'
  },
  ...defaultSettingsProps
})

const onFormSubmit = () => {
  let data = {
    first_name: profile.value.first_name,
    last_name: profile.value.last_name,
    code: code.value
  }
  if (profile.value.phone) data.phone = profile.value.phone

  orderStore.update(data)

  profileCompletionStore.profileCompletion(orderData.value.deal_id)
  router.push('/serviceData')
}
onBeforeMount(() => {
  checkTurnMessengers()
})
</script>
<style scoped></style>
