<template>
  <div class="flex items-center justify-between">
    <router-link type="button" class="go_back_button group" :to="backButtonUrl">
      <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.28801 1.87399L6.22701 0.812988L0.0400085 7L6.22701 13.187L7.28801 12.126L2.91101 7.75H22V6.25H2.91101L7.28801 1.87399Z"
          class="go_back_button_svg"
        />
      </svg>
      <span class="go_back_button__text">{{ backButtonText }}</span>
    </router-link>
    <span class="!mt-9 flex gap-1 text-black dark:text-white"
      >Step
      <p class="text-main">1</p>
      of 5</span
    >
  </div>
  <div class="flex flex-col gap-x-16">
    <h1 class="title mb-[48px] md:text-[80px]/[88px] lg:w-min">Contact</h1>
    <SummaryInfo
      customStyle="true"
      classnames="summary_title_button_wrapper form_to_edit lg:mt-5 md:items-start"
      title="Ride Summary"
      disabled="false"
    />
  </div>
  <div class="contact_form_wrapper">
    <div class="w-full">
      <div v-if="contactsData.length" class="">
        <ContactsChoise @getContact="getContact($event)" />
      </div>
      <Form
        @submit="onSubmit"
        class="form"
        :validation-schema="contactSchema"
        v-slot="{ errors, isSubmitting }"
      >
        <span v-if="isSubmitting">
          {{ errorFill(errors) }}
        </span>

        <div
          v-if="!utils.isEmpty(errorOnForm) && isShowModal"
          @click="closeModal"
          class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
        >
          <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
            <div class="flex justify-end p-4">
              <button
                @click="closeModal"
                aria-label="close"
                class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
                type="button"
              >
                <svg
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="p-6 pt-0">
              <p :key="index" v-for="(error, index) in errors">{{ error }}</p>
            </div>
            <div class="border-t border-gray-600 p-6">
              <button
                @click="closeModal"
                type="button"
                class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
              >
                Ok
              </button>
            </div>
          </div>
        </div>

        <Field
          type="text"
          maxlength="50"
          name="first_name"
          class="input ym-record-keys"
          :class="errors.first_name ? 'error' : ''"
          v-model="firstName"
          placeholder="First Name*"
          @beforeinput="utils.isLetter($event)"
        />
        <Field
          type="text"
          maxlength="50"
          name="last_name"
          class="input ym-record-keys"
          :class="errors.last_name ? 'error' : ''"
          v-model="lastName"
          placeholder="Last Name*"
          @beforeinput="utils.isLetter($event)"
        />
        <Field
          type="email"
          maxlength="230"
          name="email"
          v-model="email"
          class="input ym-record-keys"
          :class="errors.email ? 'error' : ''"
          placeholder="E-mail Address*"
        />
        <vue-tel-input
          v-model="phone"
          maxlength="230"
          @country-changed="countryChanged"
          v-on:beforeinput="utils.isNumber($event)"
          class="input ym-record-keys"
          :dropdownOptions="{
            showFlags: true,
            showDialCodeInList: true,
            showDialCodeInSelection: true
          }"
          :defaultCountry="country_prefix"
          v-bind="bindProps"
        ></vue-tel-input>
        <div class="next_step_button_wrapper">
          <button :disabled="isSubmitting" type="submit" class="next_step_button">Next</button>
          <p class="privacy_text">
            By clicking Get a Quote, you accept our
            <a
              target="_blank"
              :href="projectLink + 'terms-and-conditions/'"
              class="text-dark_green text-main"
              >Terms & conditions</a
            >
            and
            <a
              target="_blank"
              :href="projectLink + 'privacy-policy/#cookiepolicypar'"
              class="text-dark_green text-main"
              >Privacy policy.</a
            >
          </p>
        </div>
      </Form>
    </div>
  </div>
</template>
<script setup>
import SummaryInfo from '@/components/SummaryInfo.vue'
import ContactsChoise from '@/components/ContactsChoise.vue'
import * as Sentry from '@sentry/browser'
import { inject, onBeforeMount, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import utils from '@/plugins/utils'
import { useCarsStore } from '@/stores/cars'
import { useMainStore } from '@/stores/main'
import { useOrderStore } from '@/stores/order'
import { useUserStore } from '@/stores/user'
import { useContactsStore } from '@/stores/contacts'
import { storeToRefs } from 'pinia'
import { Field, Form } from 'vee-validate'
import * as yup from 'yup'
// import ChooseAllContact from '@/components/ChooseAllContact.vue'

const projectLink = ref(import.meta.env.VITE_PROJECT_URL)
const carsStore = useCarsStore()
const { cars, selectedCar } = storeToRefs(carsStore)
const orderStore = useOrderStore()
const { orderData, orderId, fleet } = storeToRefs(orderStore)
const userStore = useUserStore()
const contactsStore = useContactsStore()
const { contactsData } = storeToRefs(contactsStore)
const { user } = storeToRefs(userStore)
const mainStore = useMainStore()
const { isRequesting } = storeToRefs(mainStore)
const { selectedContact } = storeToRefs(contactsStore)

watch(selectedContact, () => {
  firstName.value = selectedContact.value.first_name
  lastName.value = selectedContact.value.last_name
  email.value = selectedContact.value.email
  code.value = selectedContact.value.code
  country_prefix.value = selectedContact.value.country_prefix
  phone.value = selectedContact.value.phone
})

const isShowModal = ref(true)
const errorOnForm = ref(null)
const errorFill = (errorBag) => {
  errorOnForm.value = errorBag
}

watch(errorOnForm, (newVal) => {
  if (!utils.isEmpty(newVal)) {
    isShowModal.value = true
  }
})

function closeModal() {
  isShowModal.value = false
}

const router = useRouter()
const axios = inject('axios')
const regexLink = inject('regexLink')
const regexIsHttps = inject('regexIsHttps')
const regexNameField = inject('regexNameField')
const isTour = orderData.value.type_of_service === 'toursRoadshows'

const firstName = ref(null)
const lastName = ref(null)
const email = ref(null)
const phone = ref(null)
const code = ref(null)
const country_prefix = ref(null)
const backButtonUrl = ref(isTour ? '/vehicle' : '/')
const backButtonText = ref(isTour ? 'Go Back to Select Vehicle' : 'Go Back')

function getContact(contact) {
  firstName.value = contact.first_name
  lastName.value = contact.last_name
  email.value = contact.email
  code.value = contact.code
  country_prefix.value = contact.country_prefix
  phone.value = contact.phone
}

const countryChanged = (country) => {
  const countryName = country.name.split(' (')[0]
  localStorage.setItem('selectedCountry', countryName)
  code.value = country.dialCode
  country_prefix.value = country.iso2
}

const bindProps = reactive({
  mode: 'national',
  autoFormat: false,
  enabledFlags: true,
  preferredCountries: ['us', 'it', 'au', 'uk', 'in', 'ca', 'il', 'sg', 'ae'],
  inputClasses: 'input'
})

const emailDomains = {
  gmail: 'com',
  hotmail: 'com'
}

const contactSchema = yup.object({
  email: yup
    .string()
    .email('Email must be email')
    .required('Email is required')
    .test(
      'email-dot',
      'The email address must contain a dot (.) in the second part (after the @ symbol), as addresses without a dot will not be accepted',
      (value) => {
        return value.includes('.')
      }
    )
    .test('email-right-domain', 'The email has not a valid domain', (value) => {
      const listParts = value.split('@')
      if (listParts.length) {
        const listDomain = listParts[1]
        if (listDomain) {
          const listDomainParts = listDomain.split('.')
          if (listDomainParts.length === 2) {
            let domainZone = listDomainParts.pop()
            if (Object.prototype.hasOwnProperty.call(emailDomains, listDomainParts[0])) {
              return emailDomains[listDomainParts[0]] === domainZone
            }
            return true
          }
          return true
        }
        return true
      }
      return true
    })
    .max(230)
    .matches(regexIsHttps, {
      excludeEmptyString: true,
      message: 'Email should not contain special characters or links'
    })
    .matches(regexLink, {
      excludeEmptyString: true,
      message: 'Email should not contain special characters or links'
    }),
  first_name: yup
    .string()
    .required('First name is required')
    .max(50, 'First name should not exceed 50 characters')
    .matches(regexNameField, {
      excludeEmptyString: true,
      message: 'First name should not contain special characters or links'
    })
    .matches(regexIsHttps, {
      excludeEmptyString: true,
      message: 'First name should not contain special characters or links'
    })
    .matches(regexLink, {
      excludeEmptyString: true,
      message: 'First name should not contain special characters or links'
    }),
  last_name: yup
    .string()
    .required('Last name is required')
    .max(50, 'Last name should not exceed 50 character')
    .matches(regexNameField, {
      excludeEmptyString: true,
      message: 'Last name should not contain special characters or links'
    })
    .matches(regexIsHttps, {
      excludeEmptyString: true,
      message: 'Last name should not contain special characters or links'
    })
    .matches(regexLink, {
      excludeEmptyString: true,
      message: 'Last name should not contain special characters or links'
    })
})

const createOrder = async (callback) => {
  await axios
    .post('/orders', orderData.value)
    .then(function (response) {
      callback(response)
    })
    .catch(async function (error) {
      Sentry.captureException(error)
    })
}

const onSubmit = async (values) => {
  isRequesting.value = true
  values.code = code.value
  values.phone = phone.value
  values.country_prefix = country_prefix.value

  if (orderId.value === 'undefined' || orderId.value === 'null') {
    orderId.value = null
  }

  orderStore.update(values)

  orderData.value.fromStart = true

  //TODO: Remake contact callbacks

  if (user.value?.id) {
    orderStore.update({
      user_id: user.value.id
    })
  }

  if (!orderId.value) {
    if (orderData.value.type_of_service === 'toursRoadshows') {
      await createOrder((response) => {
        let data_cars = response.data.data.cars
        let data_order = response.data.data.order
        carsStore.update(data_cars)
        orderStore.update(data_order)
        orderStore.updateOrder(data_order)
        orderStore.updateOrderId(data_order.id)
        isRequesting.value = false
        orderData.value.allowedPages['success'] = 1
        router.push('/success')
      })
    } else if (orderData.value.type_of_service === 'hourlyAsDirected') {
      await createOrder((response) => {
        let data_order = response.data.data.order
        let data_cars = response.data.data.cars
        carsStore.update(data_cars)
        orderStore.update(data_order)
        orderStore.updateOrder(data_order)
        orderStore.updateOrderId(data_order.id)
        if (!fleet.value) {
          orderData.value.allowedPages['vehicle'] = 1
          router.push('vehicle')
        } else {
          const carFromStore = cars.value.filter((e) => e.class_id === selectedCar.value.class_id)
          orderStore.update({ total: carFromStore[0].price })
          let data = {
            car: carFromStore[0].class_id,
            total: carFromStore[0].price,
            step: 2
          }

          axios
            .patch('/orders/' + orderId.value, data)
            .then(function () {
              axios
                .post('transaction/fetch/' + orderId.value)
                .then(function (response) {
                  isRequesting.value = false
                  router.push('/payment/' + response.data.data.transaction_id)
                })
                .catch(function (error) {
                  isRequesting.value = false
                  Sentry.captureException(error)
                })
            })
            .catch(function (error) {
              isRequesting.value = false
              Sentry.captureException(error)
            })
        }
      })
    } else if (orderData.value.type_of_service === 'oneWayTransfer') {
      await createOrder((response) => {
        response = response.data.data

        carsStore.update(response.cars)
        orderStore.update(response.order)
        orderStore.updateOrder(response.order)
        orderStore.updateOrderId(response.order.id)

        if (response.order.distance !== undefined) {
          if (response.order.distance) {
            orderStore.update({ distance: response.order.distance })
          } else {
            orderStore.update({ distance: 0 })
          }
        }

        if (!fleet.value) {
          const search_data_order_id = orderId.value

          orderData.value.allowedPages['vehicle'] = 1
          axios
            .post('/fields/search/' + search_data_order_id + '', {})
            .then(function () {
              isRequesting.value = false
              router.push('vehicle')
            })
            .catch(function (error) {
              isRequesting.value = false
              Sentry.captureException(error)
            })
        } else {
          let search_data_order_id = orderId.value
          const carFromStore = cars.value.filter((e) => e.class_id === selectedCar.value.class_id)
          orderStore.update({ total: carFromStore[0].price })
          axios
            .post('/fields/search/' + search_data_order_id, {})
            .then(function () {
              if (orderData.value.distance === 0) {
                isRequesting.value = false
                orderData.value.allowedPages['success'] = 1
                router.push('/success')
              } else {
                let data = {
                  car: carFromStore[0].class_id,
                  total: carFromStore[0].price,
                  step: 2
                }

                axios
                  .patch('/orders/' + orderId.value, data)
                  .then(function () {
                    axios
                      .post('transaction/fetch/' + orderId.value)
                      .then(function (response) {
                        isRequesting.value = false
                        router.push('/payment/' + response.data.data.transaction_id)
                      })
                      .catch(function (error) {
                        isRequesting.value = false
                        Sentry.captureException(error)
                      })
                  })
                  .catch(function (error) {
                    isRequesting.value = false
                    Sentry.captureException(error)
                  })
              }
            })
            .catch(function (error) {
              isRequesting.value = false
              Sentry.captureException(error)
            })
        }
      })
    }
  } else {
    isRequesting.value = true

    axios
      .post(`orders/update/orderSummary/${orderId.value}`, orderData.value)
      .then((response) => {
        response = response.data.data

        orderStore.updateOrder(response.order)

        if (response?.cars.length != 0) {
          carsStore.update(response.cars)
        }

        isRequesting.value = false
      })
      .catch((error) => {
        isRequesting.value = false
        Sentry.captureException(error)
      })

    if (isTour) {
      orderData.value.allowedPages['success'] = 1
      await router.push('success')
    } else {
      orderData.value.allowedPages['vehicle'] = 1
      await router.push('vehicle')
    }
  }
}

onBeforeMount(async () => {
  country_prefix.value = orderData.value.country_prefix
  firstName.value = orderData.value.first_name
  lastName.value = orderData.value.last_name
  code.value = orderData.value.code
  phone.value = orderData.value.phone
  email.value = orderData.value.email

  if (orderData.value.allowedPages.success_payment_intent) {
    orderStore.$reset()
    await router.push('forbidden')
  }
  if (
    orderData.value.utm_source != null &&
    (orderData.value.redirectStep < 1 || typeof orderData.value.redirectStep == 'undefined')
  ) {
    orderData.value.redirectStep = 1
  }

  switch (orderData.value.type_of_service) {
    case 'oneWayTransfer':
      orderStore.update({
        reqs: null,
        hours: null,
        extra_kms: null
      })
      break
    case 'hourlyAsDirected':
      orderStore.update({
        dropoff: null,
        reqs: null
      })
      break
  }

  contactsStore.removeNullPhone()

  // Clear Timer in Payment
  orderData.value.countdown = 'stop'
})
</script>
